import React from 'react'

const GENETIC_COUNSELLING = () => {
  return (
    <>

    <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
      <div className="col text-center strip1" style={{ color: 'white' }}>
        <div className="row mt-4" >
          <h1>GENETIC COUNSELLING</h1>
        </div>
        <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
          <li ><a href="/Home">Home</a></li>
          <li ><a href="/">Services</a></li>
          <li ><a href="/">Genetic Counselling</a></li>
        </ul>
      </div>
    </div>



    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
      <div className="row">
        <div className="col-lg-7 mb-2">
          <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#b066d4" }}>GENETIC COUNSELLING</span>
          </h1>
          <p>
          Genetic counsellors can review your family and medical history to help you learn about how certain conditions are inherited. They can give you information about genetic tests and provide advice on support services.          </p>
          
          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#dc48a8" }}>What is genetic counselling?</span>
          </h4 >
          <p style={{ textAlign: 'justify' }}>
          Genetic counselling involves talking about a genetic condition with a health professional who has qualifications in both genetics and counselling.
          </p>
          <p>Genetic conditions are caused by changes or mistakes in genes. These conditions may be inherited (passed down) from one or both parents.</p>
          <p>
          Genetic counselling can help you understand more about an inherited condition and what causes it.
          </p>
         
        </div>
        <div  style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
          <img className="mt-5" style={{ height: '300px',borderRadius:'15px' }} id="ServicesImg" src='GeneticImage.jpg' alt=''></img>
        </div>
      </div>
      <p>
          A genetic counsellor can talk you through genetic testing. They can also help you and your family adjust to living with a genetic condition and plan for the future.
          </p>
          <p>
          A genetic counsellor can talk you through genetic testing. They can also help you and your family adjust to living with a genetic condition and plan for the future.
          </p>

          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#dc48a8" }}>What is genetic testing?</span>
          </h4 >
          <p>If you have symptoms or a family history of a genetic condition, your doctor may recommend genetic testing.</p>
          <p>Genetic testing can diagnose a genetic condition.</p>
          <p>Genetic testing can also tell you about the chance of passing a genetic condition on to your children. This type of testing is called reproductive genetic carrier screening.</p>
          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#dc48a8" }}>Prenatal testing</span>
          </h4 >
          <p>Pregnancy checkups and scans, including prenatal screening and testing, are available to check the health of your baby during pregnancy. Sometimes the screening tests find an increased risk of a health problem. If the tests detect an increased risk of a chromosomal condition, such as Down syndrome, further testing may be recommended.</p>
          <p>The tests may include:</p>
          <ul>
            <li>amniocentesis</li>
            <li>chorionic villus sampling (CVS)</li>
           
          </ul>
          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#dc48a8" }}>How will I know if I need genetic counselling?</span>
          </h4 >
          <p>There are many reasons to see a genetic counsellor. You might consider genetic counselling for the following reasons.</p>
          <ul>
            <li>If you have a genetic condition.</li>
            <li>If there is a chance you have an genetic condition because it runs in your family.</li>
            <li>If you have a family history of young-onset cancers like bowel, breast, or ovarian cancer.</li>
            <li>If you have a child that is showing signs of a genetic disorder.</li>
            <li>If you are planning a pregnancy and you want to understand the likelihood of your baby having an inherited condition.</li>
            <li>If you are pregnant and have a prenatal test that shows an abnormality or risk of a health problem with your baby.</li>
            <li>If you are thinking of having a child with a blood relative.</li>
            
          </ul>
          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#dc48a8" }}>What can I expect in a genetic counselling session?</span>
          </h4 >
          <p>Genetic counselling sessions usually combine many different elements:</p>
          <ul>
            <li>Talking about your family and any conditions that are common in your family</li>
            <li>Learning about specific conditions and how they are inherited.</li>
            <li>Finding out what tests are available.</li>
            <li>Talking about how to manage and prevent conditions.</li>
            <li>Learning what support and resources exist.</li>
            <li>Organising genetic tests.</li>
          </ul>
          <p>Genetic counselling aims to increase your understanding of genetic conditions. These may be conditions that you have, or that run in your family.</p>
    </div>

  </>
  )
}

export default GENETIC_COUNSELLING