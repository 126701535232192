import React from 'react'

const IUI = () => {
  return (
    <>

      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>INTRAUTERINE INSEMINATION</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="/IUI">IUI</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>IUI-Intrauterine Insemination</span>
            </h1>
            <p>
            Intrauterine insemination is a medical procedure that involves injecting good-quality sperm directly into a woman’s uterus. This procedure is performed to ensure that more sperm reach the fallopian tube to give the patient a higher chance of the sperm fertilizing the egg.
            </p>
            
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#dc48a8" }}>Who is suitable for IUI?</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
            IUI offers men with low sperm count or low sperm motility an option to give their sperm a head starts by injecting it directly into the uterus. Women who have severe cervical mucus hostility which doesn’t allow for the sperm to survive should opt for IUI. Women who have a good egg reserve and open healthy fallopian tubes are ideal candidates to undergo IUI treatment. In cases of azoospermia (complete absence of sperms) donor sperms can be used for IUI with consent.
            </p>

          </div>
          <div  style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px',borderRadius:'10px' ,width:'400px',boxShadow:'grey' }} id="ServicesImg" src='iuiImage4.jpg' alt=''></img>
          </div>
        </div>

      </div>

    </>
  )
}

export default IUI