import React from 'react'
import './App.css'
import {

  NavLink,

  Col,
  Row,
  Navbar,
  NavDropdown,
  NavbarBrand,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';

 const FERTILITY_PRESERVATION = () => {
  return (
    <>

    {/* ALTERNATE NAVBAR..... */}
      <Navbar className='testing' collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container className='testing1' >
          <NavbarBrand><a><img src='BHAVYAlogo4.png' alt="" style={{ height: '100px', width: '300px', paddingTop: '10px', paddingBottom: '10px' }}></img></a></NavbarBrand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="testing3"id="responsive-navbar-nav">
            <Nav className="msss ms-auto">
              <Nav.Link href="#pricing">Home</Nav.Link>
              <NavDropdown
                className="mssss w-100% justify-content-center "
                title="Services"
                id="basic-nav-dropdown"
                style={{
                  borderTopLeftRadius: '0',
                  borderTopRightRadius: '0',

                }}


              >

                <Container id="tst"className="eventsNav p-3 my-3 " style={{marginLeft:'300px', left:'50%',transform:'translateX(-50%)',backgroundColor:'white' }}>
                  <Row style={{left:'50%'}}>
                    <Col xs="12" md="3" className="col-3 text-left">
                      <NavDropdown.Item href="/Publication">Publication</NavDropdown.Item>
                      <NavDropdown.Item href="Our_Facilities">Our Facilities</NavDropdown.Item>
                      <NavDropdown.Item href="Succes_Stories">Success Stories</NavDropdown.Item>
                      <NavDropdown.Item href="World_class_facilities">World Class Facilities</NavDropdown.Item>
                    </Col>
                    <Col xs="12" md="3" className="text-left">
                      <NavDropdown.Item href="/Publication">Publication</NavDropdown.Item>
                      <NavDropdown.Item href="Our_Facilities">Our Facilities</NavDropdown.Item>
                      <NavDropdown.Item href="Succes_Stories">Success Stories</NavDropdown.Item>
                      <NavDropdown.Item href="World_class_facilities">World Class Facilities</NavDropdown.Item>
                    </Col>

                    <Col xs="12" md="3" className="text-left">

                      <NavDropdown.Item href="/Publication">Publication</NavDropdown.Item>
                      <NavDropdown.Item href="Our_Facilities">Our Facilities</NavDropdown.Item>
                      <NavDropdown.Item href="Succes_Stories">Success Stories</NavDropdown.Item>
                      <NavDropdown.Item href="World_class_facilities">World Class Facilities</NavDropdown.Item>
                    </Col>

                    <Col xs="12" md="3" className="text-left">
                      <NavDropdown.Item href="/Publication">Publication</NavDropdown.Item>
                      <NavDropdown.Item href="Our_Facilities">Our Facilities</NavDropdown.Item>
                      <NavDropdown.Item href="Succes_Stories">Success Stories</NavDropdown.Item>
                      <NavDropdown.Item href="World_class_facilities">World Class Facilities</NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>
              <NavDropdown
                // className="pr-2 py-2 align-text-top"
                title="Why Choose Us"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/Publication">Publication</NavDropdown.Item>
                <NavDropdown.Item href="Our_Facilities">Our Facilities</NavDropdown.Item>
                <NavDropdown.Item href="Succes_Stories">Success Stories</NavDropdown.Item>
                <NavDropdown.Item href="World_class_facilities">World Class Facilities</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                // className="pr-2 py-2 align-text-top"
                title="About"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="#action3">dr</NavDropdown.Item>
                <NavDropdown.Item href="#action4">dr</NavDropdown.Item>
                <NavDropdown.Item href="#action5">Google Reviews</NavDropdown.Item>
              </NavDropdown>

            </Nav>
            <Nav>
              <Nav.Link href="/Contact">Contact us</Nav.Link>
              <Nav.Link href=""><Button className="btn" style={{ backgroundColor: '#b066d4' }}>
                Book Appointment
              </Button></Nav.Link>
              <Nav.Link href="#action1"><button className='btn' style={{ backgroundColor: '#b066d4', color: 'white' }}>Call Us</button></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  )
}
 export default FERTILITY_PRESERVATION