import React from 'react'
import Home from './Home'

import Footer from './footer'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './Contact';

import Publication from './publication';
import { Header } from './Header';
import ICSI from './ICSI';
import PICSI from './PICSI';
import IUI from './IUI';
import TESA from './TESA';
import TESE from './TESE';
import PESA from './PESA';
import FERTILITY_PRESERVATION from './FERTILITY_PRESERVATION';
import SEMEN_FREEZING from './SEMEN_FREEZING';
import BLASTOCYST_CULTURE from './BLASTOCYST_CULTURE';
import PGT_EMBRYO_BIOPSY from './PGT_EMBRYO_BIOPSY';
import FERTILITY_ENHANCING_LAPAROSCOPY from './FERTILITY_ENHANCIN_LAPAROSCOPY';
import GENETIC_COUNSELLING from './GENETIC_COUNSELLING';
import IVF from './IVF';
import Embryo_Freezing from './Embryo_Freezing';
import Our_Facilities from './Our_Facilities';
import Succes_Stories from './Succes_Stories';
import World_class_facilities from './World_class_facilities';
import Dr_Annapurna_Bhavya_Reddy from './Dr_Annapurna_Bhavya_Reddy';
import Dr_Y_Revathi_Reddy from './Dr_Y_Revathi_Reddy';

import GoToTop from './GoToTop';
import Why_Choose_Us from './Why_Choose_Us';

import {Helmet, HelmetProvider } from 'react-helmet-async';
const App = () => {
  return (
    <>
      <Header />
      <BrowserRouter>
      <HelmetProvider>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Best IVF & Fertility Clinic</title>

          <meta property="og:title" content="Best Hospital in Guntur" />
          <meta property="og:site_name" content="Bhavya IVF & Fertility Clinic Guntur" />
          <meta property="og:url" content="http://bhavyaivfandfertility.in/" />
          <meta property="og:image" content="http://bhavyaivfandfertility.in/%PUBLIC_URL%/bhavya mam logo 4.png" />
        </Helmet>
      </HelmetProvider>
       

        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Dr_Annapurna_Bhavya_Reddy" element={<Dr_Annapurna_Bhavya_Reddy />} />
          <Route path="/Dr_Y_Revathi_Reddy" element={<Dr_Y_Revathi_Reddy />}></Route>
          <Route path="/Publication" element={<Publication />} />
          <Route path="/Succes_Stories" element={<Succes_Stories />} />
          <Route path="/World_class_facilities" element={<World_class_facilities />} />
          <Route path="/Our_Facilities" element={<Our_Facilities />} />
          <Route path="/IVF" element={<IVF />} />
          <Route path="/ICSI" element={<ICSI />} />
          <Route path="/PICSI" element={<PICSI />} />
          <Route path="/IUI" element={<IUI />} />
          <Route path="/TESA" element={<TESA />} />
          <Route path="/TESE" element={<TESE />} />
          <Route path="/PESA" element={<PESA />} />
          <Route path="/Embryo_Freezing" element={<Embryo_Freezing />} />
          <Route path="/FERTILITY_PRESERVATION" element={<FERTILITY_PRESERVATION />} />
          <Route path="/SEMEN_FREEZING" element={<SEMEN_FREEZING />} />
          <Route path="/BLASTOCYST_CULTURE" element={<BLASTOCYST_CULTURE />} />

          <Route path="/PGT_EMBRYO_BIOPSY" element={<PGT_EMBRYO_BIOPSY />} />
          <Route path="/FERTILITY_ENHANCING_LAPAROSCOPY" element={<FERTILITY_ENHANCING_LAPAROSCOPY />} />
          <Route path="/GENETIC_COUNSELLING" element={<GENETIC_COUNSELLING />} />
          <Route path="/Why_Choose_Us" element={<Why_Choose_Us />} />
          <Route path="*" element={<Home />} />

        </Routes>
      </BrowserRouter>
      <GoToTop />
      <Footer />
    </>
  )
}
export default App
