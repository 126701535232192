import React from 'react'

const TESA = () => {
  return (
    <>
      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>Testicular Epididymal Sperm Aspiration</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="/TESA">TESA</a></li>
          </ul>
        </div>
      </div>



      <div class="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div class="row">
          <div class="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>Testicular Epididymal Sperm Aspiration (TESA)</span>
            </h1>
            <p>
            TESA or Testicular Sperm Aspiration is one of the newest sperm retrieval techniques. This treatment is recommended for men with Azoospermia, a condition in which a lack of sperm is found in their ejaculate. In the past, the only options for treating azoospermia were reconstructive surgery (if there was obstruction) or donor insemination. Now, the advent of intra-cytoplasmic sperm injection (ICSI) has enabled many azoospermia men to become biological fathers using sperm obtained from their epididymis or testis.
            </p>
            <h5 style={{ textAlign: 'justify' }}>
              This surgical sperm retrieval procedure is used in men:
            </h5>
            <ul>
              <li>If their testicles are not making enough sperm or Non-obstructive Azoospermia or</li>
              <li>If due to some blockage, the sperms get blocked from passing out to the semen or Obstructive Azoospermia</li>
            </ul>

          </div>
          <div style={{ textAlign: 'center' }} class="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px' ,borderRadius:'10px'}} id="ServicesImg" src='tesaImage.jpg' alt=''></img>
          </div>
        </div>

        <p style={{ textAlign: 'justify' }}>
        Extremely poor sperm production can be a hindrance to their fertility. In such cases, TESA has been a major breakthrough in extracting sperm directly from a man’s testis. Men with azoospermia can father a child if sperm is retrieved from their testis or epididymis. This procedure is used in men with non-obstructive Azoospermia, wherein no sperms can be retrieved from the epididymis.
        </p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>How is TESA Done?</span>
        </h4 >

        <p style={{ textAlign: 'justify' }}>
          Testicular sperm aspiration is performed by inserting a needle into the testis and aspirating fluid and tissue using negative pressure. The sample is then processed to extract the sperm cells in the embryology laboratory using the ICSI or Intracytoplasmic sperm injection.
        </p>

        <p style={{ textAlign: 'justify' }}>
          The sperm cells need not have to be mature and pass through epididymis to fertilize an egg. Since the testicular sperm is immature, they need ICSI, a technique in which a single sperm is taken from the semen and injected directly into the egg.
        </p>

        <p style={{ textAlign: 'justify' }}>
        TESA can give some discomfort for a few days as it’s a slightly complex procedure. It is done under local anaesthesia or sedation as per the patient’s preference in daycare no need for any hospital stay. If TESA doesn’t provide enough sperm, a testicular biopsy is needed in such cases.
        </p>

        <p style={{ textAlign: 'justify' }}>
          Many speculations and concerns have been raised about the use of genetic material from a developing sperm cell. Also, there can be a chance that the cause of infertility can be transmitted to the progeny.
        </p>
      </div>
    </>
  )
}
export default TESA