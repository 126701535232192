import React from 'react'
import './App.css'


const IVF = () => {

    return (
        <>

            <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>INVITRO FERTILISATION</h1>
                    </div>
                    <ul classname="links container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                        <li ><a href="/">Services</a></li>
                        <li ><a href="/IVF">IVF</a></li>
                    </ul>
                </div>
            </div>



            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#b066d4" }}>IVF – Invitro Fertilisation</span>
                        </h1>
                        <p>
                            If you’re looking for personalized IVF Treatment in Guntur, Bhavya Fertility Centre, is the right choice for you. We specialize in all types of fertility treatments for men and women. Our fertility treatments are tailor-made for each patient, using advanced techniques that reduce side effects, stress, and duration of the treatment.                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Bhavya Fertility Centre in Guntur, Andhra Pradesh, India is one of the leading fertility hospitals, having the best IVF success rates. Our experienced fertility expert, Dr.Bhavya gives a personalized treatment approach towards Male and Female infertility problems, with advanced technology, and has achieved superior pregnancy success rates. We are committed to providing compassionate and ethical fertility treatment, recommending treatments that significantly boost your chances of having a successful pregnancy.                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 mb-3" style={{ height: '300px', borderRadius: '10px' }} id="ServicesImg" src='ivfImage.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#dc48a8" }}>Steps in IVF</span>
                </h4>
                <ul>
                    <li>Ovarian stimulation – In a natural menstrual cycle, women produce a single egg. In an IVF cycle, medication or hormones are used to stimulate the ovaries to produce more eggs. During the stimulation process,the patients is monitored with serial ultrasound examinations and hormonal blood tests.
                    </li>
                    <li>Egg collection (oocyte retrieval) – It is a minor surgical procedure done under Anesthesia. Using a thin needle through the upper vaginal wall under ultrasound guidance, follicular fluid, which contains the eggs, is aspirated. Immediately after retrieval, the egg is placed in a dish and transferred to an incubator.
                    </li>
                    <li>
                      Fertilisation – A sperm sample is collected from the male partner and added to the eggs retrieved. Sometimes, the sperm is injected into the egg directly under the high-definition microscope. This is called intra-cytoplasmic sperm injection (ICSI). The development of embryos in the IVF lab is monitored.                    </li>
                    <li>
                        Embryo transfer – A thin tube is placed through the cervix into the uterus, and the embryos are directly transferred into the uterus.
                    </li>
                </ul>
                <p style={{ textAlign: 'justify' }}>
                    Plan on resting for the rest of the day after your embryo transfer. You can go back to your normal activities the next day. You will be advised to take pills or get daily injections of a hormone called progesterone for the first 8-10 weeks after the embryo transfer. The hormones make it easier for the embryo to survive in the uterus. Pregnancy happens if any of the embryos attach to the lining of your uterus. A pregnancy test is done two weeks after embryo transfer.
                </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#dc48a8" }}>What Is the Cost Of IVF?</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                It depends on the condition of the patient &the treatment suggested. We at our Bhavya fertility center provide the most affordable IVF treatment in and around Guntur, Andhra Pradesh with a highly experienced IVF specialist.                </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#dc48a8" }}>What is the success rate of IVF ?</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Bhavya Fertility center has the most experienced Doctors , medical staff and state of the ART technologies and laboratory . From the past few years we are persistently maintaining high success rates of 60-69 % which is at par with the best in the world . Success rates varies with the age of the patient and other associated conditions.
                </p>



                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#dc48a8" }}>How many days do I need to stay in the hospital for an IVF procedure ?</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    You and your partner will need to spend about half a day at the hospital for the egg retrieval procedure . It is a day care procedure .
                </p>
            </div>
        </>
    )
}

export default IVF
