import React, { useRef, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './App.css'
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com'


import {
    MDBContainer,
    MDBCol,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow,
} from 'mdb-react-ui-kit';




export const Header = () => {
   
    const form=useRef()
    const [data, updateData] = useState({
        Name: "",
        Email: '',
        Number: '',
        Subject: '',
        Message: ''
    })
    const { Name, Email, Number, Subject, Message } = data;

    const changeHandler = e => {
        updateData({ ...data, [e.target.name]: [e.target.value] })

    }
    const submitHandler = e => {
        e.preventDefault();

        emailjs.sendForm('service_8du5oih', 'template_fk4hx5h', e.target, '5ikR1jOvWYI6nIJRF')
            .then((result) => {
                alert("Successfully Booked Your Appointment ")
                updateData({
                    Name: "",
                    Email: "",
                    Number: "",
                    Subject: '',
                    Message: ''

                })

            }, (error) => {
                console.log(error.text);
            });

    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>

            <div className='iconss' style={{ backgroundColor: '#b066d4' }}>
                <a href='tel:9736736936' ><i  className="bi bi-phone-flip" style={{color:'white'}} >9736736936</i></a>
                <a href='/'><i  className="bi bi-facebook"></i></a>
                <a href='/' ><i  className=" bi bi-twitter"></i></a>
                <a href='https://www.youtube.com/channel/UCTDifKwPbOrPCy0F5p4luCA'><i className="bi bi-youtube"></i></a>
                <a  href='/'><i  className="bi bi-instagram"></i></a>
            </div>


            {/* navigationBar */}

            <Navbar id="NavStyles" className="NavStyles" sticky="top" style={{ backgroundColor: 'black' }} bg="light" expand="lg">

                <Container fluid className='navContainer' >
                    <Navbar.Brand href="/Home" ><img src='BHAVYAlogo4.png' alt="" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '300px' }} navbarScroll>
                            <Nav.Link className='navlink' href="/home">Home</Nav.Link>
                            <NavDropdown className='homeabout' title="why choose us" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="/Why_Choose_Us">Why Choose Us</NavDropdown.Item>
                                <NavDropdown.Item href="/Publication">Publication</NavDropdown.Item>
                                <NavDropdown.Item href="Our_Facilities">Our Facilities</NavDropdown.Item>
                                <NavDropdown.Item href="Succes_Stories">Success Stories</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown className='homeabout'  title="About" id="navbarScrollingDropdown">
                                <NavDropdown.Item href="Dr_Annapurna_Bhavya_Reddy">Dr Annapurna Bhavya Reddy</NavDropdown.Item>
                                <NavDropdown.Item href="Dr_Y_Revathi_Reddy">Dr Y Revathi Reddy</NavDropdown.Item>
                               

                            </NavDropdown>

                           
                            <MDBDropdown >
                                <MDBDropdownToggle tag='a' className='nav-link navlinkss'  id="navbarScrollingDropdown" style={{height:'52px'}}>
                                    Services
                                </MDBDropdownToggle>
                                <MDBDropdownMenu 
                                    className='mt-3  justify-content-center'
                                    style={{
                                        borderTopLeftRadius: '0',
                                        borderTopRightRadius: '0',
                                    }}
                                >
                                    <MDBContainer id ="MdbContss" className="MdbConts"style={{ width: '1000px' , display:'block'}}>
                                        <MDBRow className='rows my-4 ' >
                                            <MDBCol md='6' lg='3' className='mb-3 mb-lg-0'>
                                                <MDBListGroup flush>
                                                    <MDBListGroupItem tag='a' href='/IVF' action>
                                                        IVF
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/ICSI' action>
                                                        ICSI
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/PICSI' alt="" action>
                                                        PICSI
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/IUI' action>
                                                        IUI
                                                    </MDBListGroupItem>
                                                </MDBListGroup>
                                            </MDBCol>
                                            <MDBCol md='6' lg='3' className='mb-3 mb-lg-0'>
                                                <MDBListGroup flush>
                                                    <MDBListGroupItem tag='a' href='/TESA' action>
                                                        TESA
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/TESE' action>
                                                        TESE
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/PESA' action>
                                                        PESA
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/Embryo_Freezing' action>
                                                        EMBRYO FREEZING
                                                    </MDBListGroupItem>

                                                </MDBListGroup>
                                            </MDBCol>
                                            <MDBCol md='6' lg='3' className='mb-3 mb-md-0'>
                                                <MDBListGroup flush>


                                                    <MDBListGroupItem tag='a' href='/SEMEN_FREEZING' action>
                                                        SEMEN FREEZING
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/BLASTOCYST_CULTURE' action>
                                                        BLASTOCYST CULTURE
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='/PGT_EMBRYO_BIOPSY' action>
                                                        PGT/EMBRYO BIOPSY
                                                    </MDBListGroupItem>
                                                </MDBListGroup>
                                            </MDBCol>
                                            <MDBCol md='6' lg='3'>
                                                <MDBListGroup flush>

                                                    <MDBListGroupItem tag='a' href='/FERTILITY_ENHANCING_LAPAROSCOPY' action>
                                                        FERTILITY ENHANCING LAPAROSCOPY
                                                    </MDBListGroupItem>
                                                    <MDBListGroupItem tag='a' href='GENETIC_COUNSELLING' action>
                                                        GENETIC COUNSELLING
                                                    </MDBListGroupItem>
                                                </MDBListGroup>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                           
                            <Nav.Link className='navlink' href="/Contact">Contact us</Nav.Link>
                            <Nav.Link href="#">
                                <Button className="btn" onClick={handleShow} style={{ backgroundColor: '#b066d4' }}>
                                    Book Appointment
                                </Button>
                            </Nav.Link>
                            <Nav.Link href="#action1"><button className='btn' style={{ backgroundColor: '#b066d4', color: 'white' }}>Call Us</button></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{color:'#b066d4'}}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form}  onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required= "Username is Required..." message= "Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
                  <div class="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
                  <div className ="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <input type="text" value={Subject} onChange={changeHandler} className ="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
                  <div className ="help-block with-errors"></div>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              
              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
                  <div className="help-block with-errors"></div>
          </Form.Group>
          <button type="submit" className="btn " name="contact" style={{backgroundColor:"#b066d4",color:"whitesmoke"}} >Send Message</button>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
          </Form>
        </Modal.Body>
       
      </Modal>

        </>
    )
}

