import ScrollToTop from "react-scroll-to-top";

function GoToTop() {
  return (
    <div className="GoTop"  >
      <ScrollToTop smooth style={{backgroundColor:'#b066d4',borderRadius:'50%' }}/>
    </div>
  );
}

export default GoToTop;