import React from 'react'

const Embryo_Freezing = () => {
  return (
    <>
      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>EMBRYO FREEZING</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="">Services</a></li>
            <li ><a href="">EMBRYO FREEZING</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>EMBRYO FREEZING</span>
            </h1>
            <p>
              Physicians can freeze and store unused embryos (fertilized eggs) created during IVF, which may include intracytoplasmic sperm injection (ICSI), using a process called cryopreservation, or embryo freezing. Freezing the embryos is the first step of embryo cryopreservation.
            </p>
            <p>
              The frozen embryos are then stored and later thawed, as needed, for future use in IVF. This allows patients currently undergoing IVF to save time and money on future cycles because a woman will not need to undergo additional egg retrievals and take as many medications.
            </p>
            <p>
              Typically, people elect to freeze their embryos because they want to preserve their options to become a parent at a later time. Factors such as cancer treatment, increasing age, or risk of injury (due to a medical condition or military deployment, for example) are reasons why people often consider cryopreservation.
            </p>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px',borderRadius:'15px' }} id="ServicesImg" src='embryo_freezing.jpg' alt=''></img>
          </div>
        </div>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Embryo freezing at a glance</span>
        </h4 >
        <ul>
          <li>Embryo cryopreservation involves the freezing and storage of embryos for use in future in vitro fertilization (IVF) treatments.</li>
          <li>Patients may elect to cryopreserve embryos for many reasons, such as wanting a backup plan to their original IVF procedure if they don’t initially conceive, to attempt a pregnancy in the future if they are successful, or to save money on future IVF cycles.</li>
          <li>Embryo cryopreservation is also common for fertility preservation if a man or woman is about to undergo medical treatment that may affect their ability to conceive.</li>
          <li>Research shows that there is no increase in the risk of birth defects among children born from frozen embryos compared with normal births.</li>

        </ul>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>How are embryos frozen and stored?</span>
        </h4 >
        <p>Reproductive endocrinologists use two methods to freeze embryos: vitrification (flash freezing) and slow programmable freezing. Although the processes differ greatly, each work by cooling embryonic cells with various cryoprotectants (“antifreeze” fluids). Cells, including those forming embryos, are primarily made of water. The concern with freezing embryos is that ice will form and damage cells when they are eventually thawed. Cryoprotectants are used to safeguard biological tissue from damage by preventing water from crystallizing during the freezing process.
        </p>
        <p>In the slow-freezing method, lab embryologists place embryos in a special machine that slowly cools them in stages. They then add cryoprotectants and once the embryos are frozen, they are stored in liquid nitrogen at -321 degrees Fahrenheit. The entire process takes about two hours. Vitrification is a newer, more successful technique that places embryos in a solution containing a much higher concentration of cryoprotectants. Embryos are then immersed in liquid nitrogen, instantly freezing them into a glass-like substance. Using this form of freezing, the embryo is stored before ice crystals have a chance to form, thereby increasing the embryo’s chance of survival and viability after thawing. The laboratory team at Women and Infants Fertility Centre exclusively uses vitrification for all new embryo cryopreservation.</p>
        <p>No matter the method used, frozen embryos are effectively suspended in time. All biological activity within the embryo is stopped, including cell growth or death. When needed, embryos are slowly thawed and soaked in fluids to remove cryoprotectants and restore water within cells. Frozen embryos can be stored for an indefinite period of time. However, the longest duration an embryo stayed frozen and was successfully used resulting in a healthy pregnancy was 19 years.</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>What are the risks of freezing embryos?</span>
        </h4 >
        <p>Research shows that the freezing and thawing of embryos do not harm subsequent babies made through IVF. The length of time the embryo was stored does not affect IVF success rates.</p>
        <p>With improving technology, the difference in pregnancy rates between frozen embryos and fresh is negligible. In addition, the stimulation process with frozen embryo transfer is gentler, with hormone levels closer to normal in the woman, which may also improve pregnancy rates.</p>
        <p>Any ice crystals formed during the slow freeze process may cause damage to an embryo while thawing. This is one of the reasons vitrification is the preferred cryopreservation technique. Research shows that there is no increase in the risk of birth defects among children born from frozen embryos compared with normal births.</p>
      </div>

    </>
  )
}
export default Embryo_Freezing
