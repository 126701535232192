import React from 'react'

const SEMEN_FREEZING = () => {
  return (
    <>
    <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>SEMEN FREEZING</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Sevices</a></li>
            <li ><a href="/">SEMEN FREEZING</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>SEMEN FREEZING</span>
            </h1>
            <p>
            Sperm freezing is the process of collecting, analyzing, freezing and storing a man’s sperm. The samples are later used for fertility treatments or donated to other couples or individuals, including same-sex female partners. This overall process is known as cryopreservation and is sometimes referred to as sperm banking.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "#dc48a8" }}>The cryopreservation process involves:</span>
              </h4 >
              <ul>
                <li>Routine screening for infection (HIV, hepatitis, and rapid plasma regain test for syphilis).</li>
                <li>Providing a semen sample or undergoing a sperm extraction.</li>
                <li>Lab analysis of sperm quantity and quality.</li>
                <li>Freezing of viable sperm.</li>
                <li>Storage of the sperm indefinitely.</li>
              </ul>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px',borderRadius:'15px' }} id="ServicesImg" src='SemenFreezinImage.jpg' alt=''></img>
          </div>
        </div>

      
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Who should consider freezing sperm and why?</span>
        </h4 >


        <p style={{ textAlign: 'justify' }}>
        The primary benefits of freezing sperm are to allow a man to preserve his fertility by using his sperm at a later date or to give an infertile couple, an infertile individual, or a lesbian couple a chance to conceive.
                </p>

        <p style={{ textAlign: 'justify' }}>
        Common reasons to choose to freeze sperm:
         </p>

         <ul>
          <li>Advancing age</li>
          <li>Deteriorating sperm quality or low quantity</li>
          <li>Cancer or other medical reasons</li>
          <li>Pre-vasectomy patients</li>
          <li>Transgender patients</li>
          <li>Career and lifestyle choices, such as those with high-risk occupations or who spend a lot of time away from their significant other</li>
         </ul>

          
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Risks involved in freezing sperm</span>
        </h4 >


        <p style={{ textAlign: 'justify' }}>
        There are no risks or side effects to collecting semen samples naturally (through masturbation). If surgical extraction is required, there are small risks, as with any surgery, such as bleeding or discomfort.
                </p>

        <p style={{ textAlign: 'justify' }}>
        Sperm freezing has been successfully used since 1953 to help individuals conceive healthy babies. The process is safe, standardized, and continues to improve as technology advances.

        </p>
        <p style={{ textAlign: 'justify' }}>
        The primary concern with sperm freezing is that not all sperm survive the freezing and thawing process. However, as most semen ejaculations contain an appropriate number of sperm, the chance of having enough healthy sperm for fertility treatments is very high. The capability of the surviving sperm cells to fertilize an embryo is not jeopardized during the freezing or thawing process.

        </p>
        <p style={{ textAlign: 'justify' }}>
Cryopreservation is considered to have no time limit, and stored sperm as old as 20 years have been used to create healthy babies.
        </p>
      </div>

    </>
  )
}
 export default SEMEN_FREEZING