import React from 'react'

const Dr_Y_Revathi_Reddy = () => {
  return (
    <>
        <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>ABOUT US</h1>
                    </div>
                    <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>

            <div className="container mt-4 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#b066d4" }}>Dr. Y Revathi Reddy</span>
                        </h1>

                        <p style={{ textAlign: 'justify' }}>
                        Dr. Revathi is a graduate of Dental Surgery. She did her BDS from GOVERNMENT DENTAL COLLEGE VIJAYAWADA & is a ‘GOLD MEDALIST’ of the college for being the Distinction Holder during her Bachelor’s.
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        Interestingly & uniquely; She pursued her master’s in CLINICAL EMBRYOLOGY AND PREIMPLANTATION GENETICS out of her innate interest in the field of Fertility Services. She received her Master’s Degree in MSC Clinical Embryology & Preimplantation Genetics from The University of Mysore.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                        Dr. Revathi is armed with Specialized skills Dedication and ensures great contribution to an extreme Success Rate and extreme Laboratory Standards. She is skilled in Laboratory management, All Embryology & Andrology work. Additionally, Dr. Revathi is an Expert in TROPHECTODERM BIOPSY for PGD.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                        Fun fact: Being a Chatter Box since childhood, she can easily mingle with patients & make their worries disappear, and ensures their confidence. Thanks to her fine Skills through BDS which compliments fine micromanipulations in Embryology.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5">
                        <img className=" img-thumbnail shadow-lg p-1 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '260px',marginTop:'80px' }} id="ServicesImg" src='Revathimam.jpg' alt=''></img>
                    </div>
                </div>

            </div>
    </>
  )
}

export default Dr_Y_Revathi_Reddy