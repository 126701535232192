import React from 'react'

const PGT_EMBRYO_BIOPSY = () => {
  return (
    <>
      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>PGT EMBRYO BIOPSY</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="">Pgt/Embroyo/Biopsy</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>PGT EMBRYO BIOPSY</span>
            </h1>
            <p>
              Preimplantation genetic testing, or PGT, provides hopeful parents with peace of mind and the best chance at achieving a successful pregnancy. If you choose PGT-A or PGT-M as part of your IVF cycle, it’s important to understand the embryo biopsy process and how cells from your embryos will be evaluated for chromosomal abnormalities.
            </p>
            <p>
              The embryo biopsy process and chromosome testing prevent the transfer of chromosomally or genetically abnormal embryos. As a result, our Bhavya fertility centers often recommend PGT-A for hopeful mothers who want to achieve pregnancy later in life, as well as for women who have had recurrent miscarriages and failed IVF cycles. The more specific test for genetic diseases, PGT-M, is recommended when couples have a known genetic disease that they do not wish to pass down to their future children.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#dc48a8" }}>About the embryo biopsy process </span>
            </h4 >
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 ">
            <img className="mt-2" style={{ height: '300px',borderRadius:'15px' }} id="ServicesImg" src='embryo-Biopsy.jpg' alt=''></img>
          </div>
        </div>

        <p>Once the egg is fertilized with your partner’s sperm or donor sperm, the embryos will grow and develop in the IVF laboratory until they reach the blastocyst stage, typically Day 5 or Day 6. The embryo biopsy process will occur once the embryo reaches the blastocyst stage, when the embryo has grown large enough to have hundreds of cells. Waiting until this stage allows for several cells to be removed without harming the embryo, which increases the accuracy of genetic testing.</p>
        <p>In the first step of the embryo biopsy process, an embryologist creates a tiny hole in each embryo’s outer shell, or zona, using a precise laser. Through this hole, a few cells are extracted and then sent to a separate, specialized laboratory for genetic testing. Your embryos are then frozen and stored in our IVF laboratory while you await the results.</p>
        <p>Skilled geneticists will study each cell, counting the number of chromosomes that are present. A normal embryo will contain 23 pairs of chromosomes, or 46 in total. Normal embryos, or euploid embryos, are approved for transfer. Identifying these chromosomally normal embryos allows for a better chance of achieving pregnancy. Because there is a higher pregnancy rate associated with embryos that have undergone PGT testing, a single embryo can be transferred and the chances of a high-risk, multiple pregnancy are reduced.
        </p>
        <p>Embryos that contain cells that have greater than or less than 46 chromosomes are considered abnormal, or aneuploid. These embryos will be noted as abnormal, and most couples who work with our Bhavya fertility centers will choose not to transfer them.</p>
        <p>Several disorders related to aneuploidy can cause serious and sometimes life-threatening issues in a developing baby.</p>

        <ul>
          <li>Trisomy 21, or Down syndrome</li>
          <li>Trisomy 13, or Patau syndrome</li>
          <li>Trisomy 18, or Edwards syndrome</li>
          <li>Monosomy X, or Turner syndrome</li>
          <li>Trisomy XXY, or Klinefelter syndrome</li>
        </ul>

        <p>While examining your embryos’ chromosomal makeup, the geneticist will be able to determine the sex of each embryo. One X chromosome always comes from the egg, while the sperm can contribute either an X or Y chromosome. Female embryos carry XX chromosomes, while male embryos carry XY.</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Learn more about PGT-A </span>
        </h4 >


        <p style={{ textAlign: 'justify' }}>
          Keep in mind that PGT-A can only detect chromosomal abnormalities, not specific genetic disorders such as cystic fibrosis, sickle cell or Tay-Sachs disease. If you are a known or suspected carrier of a heritable genetic disease, you may wish to go a step beyond PGT-A and request preimplantation genetic testing for monogenetic disease, or PGT-M, to take a deeper look at your embryos’ genetic makeup.
        </p>

        <p style={{ textAlign: 'justify' }}>
          To learn more about the embryo biopsy process and PGT,<a href="/contact"><span>contact </span></a> our Bhavya fertility centers to schedule a consultation.
        </p>

      </div>
    </>
  )
}

export default PGT_EMBRYO_BIOPSY 