import React from 'react'

const Why_Choose_Us = () => {
  return (
    <>
      <div className="container-fluid strips mt-5 mb-5" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>WHY CHOOSE US</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="/Succes_Stories">Why Choose Us</a></li>
          </ul>
        </div>
      </div>

        {/* Best fertility center in guntur.... */}

        <div data-aos="flip-left" data-aos-duration="2000" class="container p-5" >
        <div class="row">
          <div class="col-lg-4 mb-5" style={{ textAlign: 'center', paddingTop: '30px' }}>
            <img style={{ width: "250px", height: '250px' }} src='' alt=''></img>
          </div>

          <div class="col-lg-8 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>Best Fertility Center in Guntur with Highest Success Rate</span>
            </h1>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            BHAVYA IVF & FERTILITY Clinic is the best IVF centre in Guntur for its higher success rate in IVF treatment and allied fertility treatments. Our highly dedicated team of medical experts is our backbone. With our experienced IVF doctor, embryologists, and allied professionals, we help you conceive and deliver a healthy & cute baby. 
            </p>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            And for this, we carry out each process of the IVF treatment carefully including ovary stimulation, egg retrieval, sperm extraction, embryo transfer, and pregnancy test. If required, we use ICSI with your IVF treatment to facilitate you to have positive results. At our IVF clinic in Guntur, we are persistently maintaining high success rates of 60-69 % which is at par with the best in the world. 
            </p>
          
          </div>
        </div>
      </div>
      <div className="container text-enter">
       {/* why choose us */}

      <div className="container   p-5">
        <div className="col">
          <div className='row'>
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>Why Choose Us ?</span>
              {/* <a>
              <span style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color:'#ff69b4', fontSize:' 26px'}}>
                Bhavya Fertility Centre
                </span>
              </a> */}
            </h1>

            <p style={{ textAlign: 'center' }}>Bhavya IVF and Fertility provides Basic to advanced Fertility treatments all under one roof to the couple facing issues with fertility .We maintain transparency through out the treatment protocol and utmost respect to patient confidentiality.</p>

          </div>
        </div>
      </div>

      {/* First point..... */}

      <div className='container' data-aos="fade-up-right" data-aos-duration="2000">

        <div className='col-md-11'  >
          <div class="card mb-3">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="one.jpg" class="img-fluid rounded-start" alt="..." />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">"There is only one thing that is important about fertility, that is everything."</h5>
                  <p class="card-text">Human reproduction and fertility is an intricate balance of many events that take place in the male and female reproductive system sutle changes in any of these events may result in trouble conceiving. We at Bhavya IVF, provide a holistic approach to the problems addressing the root cause of infertility.</p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Secind point.. */}

      <div className='container choose' data-aos="fade-up-right" data-aos-duration="2000" >
        <div className='col-md-12 choose1' >
          <div class="card mb-3"  >
            <div class="row g-0">
              <div class="col-md-4">
                <img src="two.jpg" class="img-fluid rounded-start" alt="..." />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">"One Size Does Not fit all "- when it comes to fertility.</h5>
                  <p class="card-text">At Bhavya IVF, Every Couple receives a perfect treatment that is individualized based on the couple’s characteristics, cause of infertility, and previous treatment history aiming for the best fertility Outcomes.</p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* third point.. */}


      <div className='container' data-aos="fade-up-right" data-aos-duration="2000">

        <div className='col-md-11'  >
          <div class="card mb-3">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="three.jpg" class="img-fluid rounded-start" alt="..." />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">"A promise of personal care."</h5>
                  <p class="card-text">Honoured to be a part of each and every patient’s unique journey to becoming a parent.Your relationship with your physician begins the day of your 1st appointment.</p>
                  <p class="card-text">All of the procedures from initial diagnostics, Monitoring IVF procedure, and ultrasound scans will be performed by experts who will be available to answer your questions.</p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* fourth point... */}


      <div className='container choose ' data-aos="fade-up-right" data-aos-duration="2000" >
        <div className='col-md-12 choose1' >
          <div class="card mb-3"  >
            <div class="row g-0">
              <div class="col-md-4">
                <img src="four-transformed.webp" class="img-fluid rounded-start" alt="..."  />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">"A Belief in Communication."</h5>
                  <p class="card-text">Open communication is extremely important during fertility treatment. your personal IVF coordinater is available to you, by phone. You will have Direct Consultation with our Embryology staff.</p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* fifth point..... */}


      <div className='container mb-5 ' data-aos="fade-up-right" data-aos-duration="2000" >
        <div className='col-md-12 ' >
          <div class="card mb-3"  >
            <div class="row g-0">
              <div class="col-md-4">
                <img src="five.jpg" class="img-fluid rounded-start" alt="..." style={{height:'260px'}} />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">" State of art: IVF Laboratory."</h5>
                  <p class="card-text">In addition to providing core Services like IUI, and IVF, Bhavya IVF provides several states of art technologies like Blastocyst culture, Embryo vitrification.</p>
                  <p class="card-text">Fertility Preservation for cancer patients in the form of egg freezing and Sperm Freezing.</p>
                  <p class="card-text">ERA (Endometrial Receptivity Array) to find out the best possible time for Embryo transfer in patients with multiple IVF failures previously.</p>
                  <p class="card-text">Our IVF Laboratory design maintenance, Equipment is all in accordance with ICMR guidelines.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>
    </>
  )
}

export default Why_Choose_Us