import React from 'react'

const PESA = () => {
  return (
    <>
      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>PERCUTANEOUS EPIDIDYMAL SPERM ASPIRATION</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="">PESA</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>PERCUTANEOUS EPIDIDYMAL SPERM ASPIRATION (PESA)</span>
            </h1>
            <p>
              Male infertility is not easy to deal with.  It is difficult for many males to bring the infertility issue due to the social stigma associated with it, making dealing with male infertility more challenging than infertility in females.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Complex testing methods are used for diagnosing and understanding the root cause of male infertility.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Some of the most common causes of male infertility could be poor quality sperm, lower than normal sperm count, and alteration in sperm motility.            </p>
            <p style={{ textAlign: 'justify' }}>
              Percutaneous Epididymis Sperm Aspiration (PESA) determines the number of sperms in a male. During this procedure, sperms are collected from the epididymis. The epididymis is the part of the testicle where sperms are stored.
            </p>
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px',borderRadius:'15px'}} id="ServicesImg" src='pesaImage.jpg' alt=''></img>
          </div>
        </div>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>What are the indications of Percutaneous Epididymal Sperm Aspiration (PESA)?</span>
        </h4 >
        <ul>
          <li>Azoospermia: Inability of the man to ejaculate sperms. It may occur due to the absence of vas deferens. the Vas deferens is a  tube that carries sperm.</li>
          <li>Failed Vasectomy</li>
          <li>Abnormalities in the ejaculation process</li>
          <li>Retrograde ejaculation where a man ejaculates sperms back in the bladder rather than in the urethra</li>
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>What is the success rate of PESA?</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
          The success rate of PESA is quite good. It is successful in almost  60 to 65 percent of cases. It is one of the most effective methods in the case of obstructive Azoospermia.            </p>
        <p style={{ textAlign: 'justify' }}>
          An embryologist can repeat PESA more than once till a sufficient number of motile sperms are available.
        </p>
        <p>The same sperms can be used for ICSI (Intra Cytoplasmic Sperm Injection).</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Is PESA a complicated surgical procedure? What are its advantages?</span>
        </h4 >

        <p>PESA is a simple surgical process involving the scrotum area. It causes minimal pain and discomfort.</p>
        <p>It’s a cost-effective procedure with almost negligible risks and complications.</p>


        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>What to expect during PESA?</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
          PESA is performed under local anesthesia. The doctor inserts a small needle in the scrotum and collects the sperms. Sperms are extracted through the needle using a suction. ICSI can be done immediately after it.
        </p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>How fast is the recovery after PESA?</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
          The patient can return home on the same day as PESA. He can start his daily routine post 24 to 48 hours after the procedure.
        </p>
        <p style={{ textAlign: 'justify' }}>
          Since there are minimal pain and discomfort after the procedure, therefore the recovery is quick with almost no complications. In case of pain, the patient can take painkillers at the recommendation of his doctor.
        </p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Risk factors:</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
          During PESA the doctor inserts a needle directly into the epididymal tubes. There can be some damage to the inner testicles during this procedure. If the procedure is repeated several times, the chances of this damage may increase
        </p>
      </div>
    </>
  )
}

export default PESA