import React from 'react'
import './footer.css'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import './App.css'


const footer = () => {
  return (
    <>
      <MDBFooter className='text-center text-lg-start text-muted' id='footercolor'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          <div className='me-5 d-none d-lg-block' style={{ backgroundColor: '#b066d4', color: 'azure' }}>
            <span>Get connected with us on social networks:</span>
          </div>

          <div style={{ backgroundColor: '#b066d4', color: 'azure' }}>
            <a href='' className='btn me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='facebook-f' />
            </a>
            <a href='' className='btn me-4 text-reset'>
              <i className="bi bi-facebook"></i>
            </a>
            <a href='' className='btn me-4 text-reset'>
              <i className="bi bi-twitter"></i>
            </a>
            <a href='' className='btn me-4 text-reset'>
              <i className="bi bi-youtube"></i>
            </a>
            <a href='' className='btn me-4 text-reset'>
              <i className="bi bi-instagram"></i>
            </a>
            <a href='' className='btn me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='github' />
            </a>
          </div>
        </section>

        <section className='' style={{ color: 'azure' }}>
          <MDBContainer className=' text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md='4' lg='4' xl='3' className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 center' style={{alignItems:'center',justifyContent:'center',marginLeft:'7px'}}>
                  <MDBIcon color='secondary' icon='gem' className='me-3' /><span className='center'>
                  <img src='bhavya mam logo 4.png' alt="" style={{ height: '150px', width: '200px', backgroundColor: 'white', borderRadius: '5%',justifyContent:'center',}} />
                  </span>
                </h6>
                <p className="text-center">
                  BHAVYA IVF & FERTLITY Clinic has been the best IVF clinic Guntur for couples with fertility problems. We have proven ourselves to be the best IVF center in Guntur  with good results.
                </p>
              </MDBCol>

              <MDBCol md='4' lg='2' xl='2' className='mx-auto mb-4 '>
                <h5 className=' fw-bold mb-4'>IVF & Infertility</h5>
                <MDBRow>
                  <MDBCol className='mx-auto mb-4 my-3 ' style={{margin:'20px'}}>
                    <p>
                      <a href='/IVF' className='text-reset'>
                        IVF
                      </a>
                    </p>
                    <p>
                      <a href='/ICSI' className='text-reset'>
                        ICSI
                      </a>
                    </p>
                    <p>
                      <a href='/TESA' className='text-reset'>
                        TESA
                      </a>
                    </p>
                    <p>
                      <a href='/PESA' className='text-reset'>
                        PESA
                      </a>
                    </p>
                    <p>
                      <a href='/SEMEN_FREEZING' className='text-reset'>
                        Semen Freezing
                      </a>
                    </p>
                   
                  </MDBCol>



                  <MDBCol  className='mx-auto mb-4 my-3 text-center'style={{margin:'40px'}}>
                    <div >
                      <p>
                        <a href='/IUI' className='text-reset'>
                          IUI
                        </a>
                      </p>
                      <p>
                        <a href='/PICSI' className='text-reset'>
                          PICSI
                        </a>
                      </p>
                      <p>
                        <a href='/TESE' className='text-reset'>
                          TESE
                        </a>
                      </p>
                      <p>
                      <a href='/Embryo_Freezing' className='text-reset'>
                        Embroyo Freezing
                      </a>
                    </p>
                    <p>
                      <a href='/BLASTOCYST_CULTURE' className='text-reset'>
                        Blastocyst Culture
                      </a>
                    </p>
                    

                    </div>

                  </MDBCol>

                </MDBRow>




              </MDBCol>


              <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4' style={{ textAlign: 'center' }}>Contact</h6>
                <p>
                  <MDBIcon color='secondary' icon='home' className='me-2' />
                  <i className="bi bi-geo-alt-fill" style={{ marginRight: '10px' }}></i>
                      D.NO, 12-12-93, Old Club Rd, Kothapeta, Guntur, Andhra Pradesh - 522001
                </p>
                
                <p>
                  <MDBIcon color='secondary' icon='envelope' className='me-3' />
                  <i className="bi bi-envelope" style={{ marginRight: '10px' }}></i>
                  info@example.com
                </p>
                <p>
                  <MDBIcon color='secondary' icon='phone' className='me-3' />
                  <i className="bi bi-telephone"></i> 9 736 736 936
                </p>
                <p>
                  <MDBIcon color='secondary' icon='phone' className='me-3' />
                  <i className="bi bi-telephone"></i> 0863 - 2450666
                </p>
                {/* <p>
                  <MDBIcon color='secondary' icon='print' className='me-3' />
                  <i className="bi bi-telephone"></i> + 01 234 567 89
                </p> */}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>


      </MDBFooter>

    </>
  )
}

export default footer
