import React from 'react'

const TESE = () => {
  return (
    <>
      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>TESTICULAR SPERM EXTRACTION</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>
            <li ><a href="/">services</a></li>
            <li ><a href="/TESE">TESE</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>Testicular Sperm Extraction (TESE)</span>
            </h1>
            <p>
              TESE or Testicular Sperm Extraction is a process where sperm is collected directly from the testicles. This surgical sperm retrieval procedure is used in men:
            </p>
            <ul>
              <li>If their testicles are not making enough sperm or Non-obstructive Azoospermia</li>
              <li>If due to some blockage, the sperms get blocked from passing out to the semen or Obstructive Azoospermia</li>
            </ul>
            <p>
              For men with such conditions, IVF (In-Vitro Fertilization) with ICSI is an obvious option to father a child. If by performing TESE, enough sperm is found in their testicles, then it will be frozen and preserved for ICSI or Intracytoplasmic sperm injection. Also, it may be recommended by your doctor to perform TESE on the day of the egg retrieval for a fresh sample to be used during ICSI or Intracytoplasmic sperm injection.

            </p>
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px', borderRadius: '15px' }} id="ServicesImg" src='TESEiMAGE.jpg' alt=''></img>
          </div>
        </div>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>How is TESE Done?</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>

          Testicular sperm extraction or TESE involves a minor theatre procedure that is performed under local or general anaesthesia. The doctor will make a small surgical incision on the patient’s scrotum. The tunica is exposed and testicular tissue is excised and small tissue is expressed. Then, with the help of sutures, the incision will be closed and a bandage will be wrapped around the scrotum.          </p>

        <p style={{ textAlign: 'justify' }}>
          The extracted tissues will be handed over to the IVF laboratory where it is screened for the presence of sperms.
        </p>


        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>What to Expect After a TESE Procedure?</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
          After the TESE, the patient will be monitored for a few hours and then discharged on the same day. The patient has to wear scrotal support, which is a special type of underwear, for a week and will be prescribed pain medication for a few days.
        </p>
        <p style={{ textAlign: 'justify' }}>
          One may feel mild pain and tenderness in their scrotum which is completely normal. The patient may experience some discomfort for 2-3 days. If there is any pain or swelling, then one must visit a doctor soon.          </p>


      </div>
    </>
  )
}

export default TESE