import React from 'react'

 const PICSI = () => {
  return (
   <>
       <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>PHYSIOLOGICAL INTRACYTOPLASMIC SPERM INJECTION</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="/PICSI">PICSI</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>Physiological intracytoplasmic sperm injection (PICSI)</span>
            </h1>
            <p>
            If you are undergoing IVF treatment, your consultant will have spoken to you about Physiological Intracytoplasmic Sperm Injection (PICSI). PICSI is a method of selecting the best possible sperm for fertilization before injection into the egg in the IVF protocol. Sperm are placed in the PICSI dish containing samples of hyaluronan (a naturally occurring biopolymer found in all human cells) hydrogel.
            </p>
            
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#dc48a8" }}>Who is PICSI</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
            PICSI is select sperm according to how well they bind to the hyaluronan around an egg cell. Mature, biochemically competent sperm bind are then isolated by the embryologist and used for ICSI.
            </p>
            <p style={{ textAlign: 'justify' }}>
            The selected sperm is essentially the same as one that would be successful in the natural reproductive process. The more mature a sperm cell, the more able it is to bind to hyaluronic acid, meaning that it is better suited to fertilizing the egg.
            </p>
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px',borderRadius:'10px'  }} id="ServicesImg" src='PICSI.jpg' alt=''></img>
          </div>
        </div>
        <p> PICSI is highly recommended in these particular cases:</p>
        <ul>
          <li>Previous failure or low fertilization even after ICSI.</li>
          <li>Low embryo quality or their failure to develop (not related to poor egg quality).</li>
          <li>Repeated miscarriages.</li>
        </ul>

      </div>

   </>
  )
}

export default PICSI