import React from 'react'

const FERTILITY_ENHANCIN_LAPAROSCOPY = () => {
  return (
    <>

      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>FERTILITY ENHANCING LAPAROSCOPY</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="">Services</a></li>
            <li ><a href="">Fertility Enhancing Laparoscopy</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>FERTILIT ENHANCING LAPAROSCOPY</span>
            </h1>
            <p>
              The two main routes of surgeries used for fertility surgery now days are laparoscopy
              and hysteroscopy. With more and more patients facing fertility issues nowadays, the
              importance of these fertility enhancing surgeries has increased manifold.
            </p>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#dc48a8" }}>Laparoscopy:</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
              With laparoscopy, we can see the uterus as well as tubes in ovaries from outside by using a
              very minimal incision in the abdomen. The outcome is as good as an open surgery without
              requiring a long hospital stay and the cost is also reduced. Also, there is no need for a big
              incision and as we are using higher magnification we can see better tissues so it definitely
              improves the outcome of the surgery as well. The most common indications for laparoscopy
              in patients with infertility are:
            </p>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px',borderRadius:'15px' }} id="ServicesImg" src='fertility-enhancing-laparoscopyImage.jpg' alt=''></img>
          </div>
        </div>
        <ul>
          <li>Evaluation of tubal patency: It can be done when we are suspecting tubal blocks like in an HSG showing bilateral tubal blocks. In patients with unexplained infertility - In patients with history of endometriosis in whom we are suspecting adhesions.</li>
          <li>Secondly, we can do laparoscopy in patients with endometriosis to do cystectomy and blade ablate the ovarian endometriomas. We can also do adhesiolysis to improve the ovarian tubal relationship and chances of conceiving.</li>
          <li>Thirdly in patients with PCOS we can do laparoscopy to do ovarian drilling and chances of ovulation in patients who are not responding to drugs alone.</li>
          <li>Fibroids - In patients with fibroids a myomectomy can be done through laparoscopy route and that can also increase the results of your fertility treatment procedures.</li>
          <li>In patients undergoing IVF - In patients undergoing IVF if we are suspecting hydrosalpinx, we can do a salpingectomy before IVF to improve the success of in vitro fertilization, so a laparoscopy can improve your chances of conceiving by evaluating your tubes, by increasing your tubo ovarian relationship in helping the evolution to occur and in improving your uterine lining if there is a fibroid present there.
          </li>
        </ul>
        <p>Also, we can do a laparoscopy to do Adhesiolysis and decrease the adhesions in the cavity.</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Hysteroscopy:</span>
        </h4 >
        <p>Hysteroscopy can be done inpatient with infertility if there is a history of failed IVF if they are suspecting thin endometrium due to asherman syndrome, if there is a polyp inside the cavity which needs to be removed if there is a history of multiple implantation failures or recurrent pregnancy losses and we are suspecting septate arcuate or bicornuate uterus.</p>
        <p>So a laparoscopy and hysteroscopy can definitely improve your chances of conceiving naturally also and also in patients who are undergoing a pre IVF laparoscopy and hysteroscopy, when done in properly selected patients can definitely improve your success rates.</p>
      </div>
    </>
  )
}
export default FERTILITY_ENHANCIN_LAPAROSCOPY