import React from 'react'

const BLASTOCYST_CULTURE = () => {
  return (
    <>
      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>BLASTOCYST CULTURE</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">services</a></li>
            <li ><a href="/">BLASTOCYST CULTURE</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>BLASTOCYST CULTURE</span>
            </h1>
            <p>
              In the IVF laboratory, after the egg retrieval procedure, fertilization is carried out by IVF (In-vitro fertilization) or by ICSI (intra-cytoplasmic sperm injection) and the resulting embryos are placed in an incubator for further development. The stages of development are as follows:
            </p>

            <ul>
              <li>The day of egg retrieval is considered Day 0 (IVF or ICSI is done after egg retrieval)</li>
              <li>The pronuclear stage on day 1 (embryologist checks as to how many eggs have successfully fertilized)</li>
              <li>The two to four-cell  stage on Day 2</li>
              <li>The eight-cell stage on day 3</li>
              <li>The morula stage on day 4</li>
              <li>The blastocyst stage on day 5 or day 6</li>

            </ul>
            <p>The blastocyst contains two different cell types- the inner cell mass which develops into the fetus, and an outer layer of cells called trophectoderm which develops into the placenta.</p>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ height: '300px',borderRadius:'15px' }} id="ServicesImg" src='BlastocystCulture.jpg' alt=''></img>
          </div>
        </div>

        <p>Historically, embryo transfer following IVF or ICSI has been carried out on Day 2 or Day 3 after egg collection. However, advances in the IVF laboratory advances allow us to now culture embryos for five or six days. This gives the embryos more opportunity to prove their developmental potential. Some embryos stop developing (arrest) prior to day 5/6. This 'natural selection' enables the embryologist to identify and choose the best embryo/s for embryo transfer.</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Advantages of Blastocyst Culture </span>
        </h4 >


        <p style={{ textAlign: 'justify' }}>
          Blastocyst culture requires optimal laboratory conditions. On average, about 45% of fertilized eggs reach the blastocyst stage.
        </p>

        <p style={{ textAlign: 'justify' }}>
          As only the embryos with the best development capacity become blastocysts, allowing embryos to grow and develop in the laboratory till they reach the blastocyst stage allows the selection of the most competent embryo/s for embryo transfer.
        </p>
        <p>Also, in a natural pregnancy, it takes around five days after fertilization for the embryo to reach the womb, where it then implants. The lining of the uterus is particularly receptive on this day. Performing blastocyst transfer hence is more physiological.</p>
        <p>Some couples require PGS (pre-implantation genetic screening) or PGD (pre-implantation genetic diagnosis). For them, blastocyst culture is required as embryo biopsy is recommended to be done in blastocyst stage rather than on Day 2/3 embryos.</p>



        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Who Can Benefit from Blastocyst Culture?</span>
        </h4 >


        <p style={{ textAlign: 'justify' }}>
          Blastocyst culture is advised for:
        </p>
        <ul>
          <li>For patients with multiple failed IVF: Performing a blastocyst culture on a patient with a history of failed IVF with day2/3 embryo transfer, can yield more information related to the growth of the embryos and aid in improving the pregnancy rate</li>
          <li>To eliminate multiple pregnancies: Blastocyst culture allows the possibility of transferring the single best embryo and thereby reducing the chance of multiple pregnancies.</li>
        </ul>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Are there any drawbacks to blastocyst transfer?</span>
        </h4 >

        <p style={{ textAlign: 'justify' }}>
          Not all clinics perform blastocyst culture. Quality control and optimal laboratory culture conditions are essential for successful blastulation.

        </p>
        <p style={{ textAlign: 'justify' }}>
          There is a risk that none of the embryos become blastocysts. In that case the embryo transfer procedure gets cancelled. In good IVF laboratories, this usually means that probably none of the embryos were competent enough to reach the blastocyst stage, and performing a day 3 transfer also probably would not have led to a pregnancy.

        </p>
        <p style={{ textAlign: 'justify' }}>
          Since fewer embryos become a blastocyst, there may be fewer or no surplus embryos to freeze after the initial embryo transfer in the fresh cycle. But, since success rates are better with a blastocyst transfer than with day 3 transfer, a frozen may not be needed after all.
        </p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Should all patients go ahead with a blastocyst transfer?</span>
        </h4 >
        <p>Depending on factors like age, medical history, and number and quality of embryos, it may be better to transfer a day3 embryo, particularly for women in their first IVF cycle.</p>
      </div>
    </>
  )
}
export default BLASTOCYST_CULTURE
