import React, { useRef, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'aos/dist/aos.css';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com'

import './App.css'


const Home = () => {

  // book appointment script........

  const form = useRef()
  const [data, updateData] = useState({
    Name: "",
    Email: '',
    Number: '',
    Subject: '',
    Message: ''
  })
  const { Name, Email, Number, Subject, Message } = data;

  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_e4unsfa', 'template_i2qlv5d', e.target, 'x9kx9l2tKiJht888k')
      .then((result) => {
        alert("Successfully Booked Your Appointment ")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [counterstart, setcounterstart] = useState(false);
  return (
    <>


      {/* slider......... */}

      <Carousel variant="dark" indicators={false}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="HomeImage2.jpg"
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="HomeImage1.jpg"
            alt="Second slide"
          />
          {/* <Carousel.Caption>
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="HomeImage3.jpg"
            alt="Third slide"
          />
          {/* <Carousel.Caption>
            <h5>Third slide label</h5>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      <div>
        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginTop: '40px' }}>
          <span style={{ color: "#b066d4" }}>Best IVF Centre In Guntur</span>
        </h1>
      </div>


      <div className="container shadow-lg p-2 mb-5 bg-body rounded hospitalContent" >
        <div className="row">
          <div className ="col-lg-8 mb-2 mt-3" style={{fontSize:'14px',paddingLeft:'50px'}}>

            <p style={{ textAlign: 'justify' }}>
            BHAVYA IVF & FERTILITY Clinic in Guntur is a top-rated fertility center, Specialized in helping couples facing fertility problems. With a team of experienced specialists and a state-of-the-art fertility lab, We offer world-class reproductive science and personalized treatment options.            </p>

            <p style={{ textAlign: 'justify' }}>

            </p>

            <p style={{ textAlign: 'justify' }}>
            At BHAVYA IVF & FERTILITY Clinic, Our dedicated professionals and nurses are committed to guiding patients throughout their fertility journey, from diagnosis to completion of treatment. We provide comprehensive support, ensuring individualized care and helping couples make the best decisions based on their specific needs. With a focus on achieving successful pregnancies and improving overall health, BHAVYA IVF & FERTILITY Clinic strives to create happy families by combining advanced science with compassion and personalized care.
            </p>
           

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-4 " >
            <img style={{ width: "200px", height: '220px' }} src='portrait1.png' alt=''></img>
          </div>
        </div>
      </div>




      {/* cards.... */}

      <div className="container text-center facility cards "  >
        <div className="row 
        " style={{ textAlign: 'center' }}>
          <div className="col mb-4  facilityColum" style={{ width: '280px', display: 'flex', height: '360px', alignItems: 'center',marginTop:'-20px' }}>
            <div className="card text-white text-center pb-4" style={{ backgroundColor: '#b066d4',height:'270px' }}>
              <div className="card-body">
                <img src="IVF_ICON2-transformed3.jpeg" style={{ width: '50px', height: '50px', borderRadius: '50%', imageOrientation: 'initial', marginBottom: '2PX' }} alt='' ></img>
                <h3 className="card-title">IVF</h3>
                <p className="lead" style={{fontSize:'15px'}}>If you’re looking for personalized IVF Treatment in Guntur, BHAVYA IVF & FERTLITY Clinic, is the right choice for you..</p>
                <a href='/IVF'><button className="btn btn-warning text-dark">Read More</button></a>
              </div>
            </div>
          </div>

          <div className="col mb-4 facilityColum " style={{ width: '280px', display: 'flex', height: '360px' }}>
            <div className="card text-white text-center pb-4 mt-4" style={{ backgroundColor: '#b066d4',height:'270px'}}>
              <div className="card-body">
                <img src="IUI4.jpg" alt='' style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX' }}></img>
                <h3 className="card-title">IUI</h3>
                <p className="lead" style={{fontSize:'15px'}}> Intrauterine insemination is a medical procedure which involves injecting good quality sperm directly.</p>
                <a href='/IUI'><button className="btn btn-warning text-dark">Read More</button></a>
              </div>
            </div>
          </div>

          <div className="col mb-4 facilityColum" style={{ width: '280px', display: 'flex', height: '360px' }}>
            <div className="card text-white text-center pb-2 mt-4" style={{ backgroundColor: '#b066d4',height:'270px' }}>
              <div className="card-body">
                <img src="icsi4.jpg" alt='' style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: 'white', marginBottom: '2PX' }}></img>
                <h3 className="card-title">ICSI</h3>
                <p className="lead" style={{fontSize:'15px'}}>Intracytoplasmic Sperm Injection or ICSI is a procedure in which a single sperm cell is directly injected.</p>
                <a href='/ICSI'><button className="btn btn-warning text-dark">Read More</button></a>
              </div>
            </div>
          </div>

          <div className="col mb-4 facilityColum" style={{ width: '280px', display: 'flex', height: '360px' }}>
            <div className="card text-white text-center pb-2 mt-4" style={{ backgroundColor: '#b066d4',height:'270px'  }}>
              <div className="card-body">
                <img src="Embryo1.jpg" alt='' style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: 'white', marginBottom: '2PX' }}></img>
                <h3 className="card-title" style={{fontSize:'22px'}}>Embryo/Egg/Sperm Donation</h3>
                <p className="lead" style={{fontSize:'15px'}}>Male infertility accounts for half of the cases of infertility problems in couples.</p>
                <a href='/Embryo_Freezing'><button className="btn btn-warning text-dark">Read More</button></a>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* meet our expert... */}

      <div className="container mb-5 p-5" data-aos="zoom-in-right" data-aos-duration="2000">
      <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '5px' }}>
              <span style={{ color: "#b066d4" }}>Meet Our Experts</span>
            </h1>
        <div className="row">
          <div className="col-lg-8 mb-2">
            
            <p>
              <strong>
                <br />
                <br />
                Dr Annapurna Bhavya Reddy<br />
              </strong>
            </p>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            Dr Bhavya did her <b>MBBS from Guntur Medical College </b> and recipient of "Best outgoing student female" during her MBBS period. She did her <b>MS in Obstetrics & Gynecology, from Government General Hospital, Guntur,</b> and won <b>Gold Medal </b> for Scoring the highest marks in University Examination.

            </p>
            
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            Her Special interest in the field of infertility made her pursue <b>FNB Reproductive Medicine</b>, at the prestigious <b>Madras Medical Mission Hospital Chennai </b>for a period of 2 years. During her FNB, she has vast Exposure to different kinds of fertility issues. With an aim of providing the best, up-to-date fertility treatments to patients of all Socio-Economic Status, she has started Bhavya IVF & Fertility, a state of art IVF center in Guntur.
            </p>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            "Our main motto is to see happiness in the faces of couples having trouble in conceiving."
                        </p>
            {/* <a href='/Contact' ><button style={{ alignItems: "right", backgroundColor: "#9932CC", color: 'white', marginBottom: '10px' }}>Read More</button></a> */}
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-4 mt-5">
            <img style={{ width: "250px", height: '250px',boxShadow: '10px 10px grey' }} src='bhavyamam1.jpg' alt=''></img>
          </div>
        </div>
    

     
        <div className="row">
        <div style={{ textAlign: 'center' }} className="col-lg-4 mt-5">
            <img style={{ width: "250px", height: '300px',boxShadow: '10px 10px grey',marginTop:'10px' }} src='Revathimam.jpg' alt=''></img>
          </div>
          <div className="col-lg-8 mb-2 mt-1">
           
            <p>
              <strong>
                <br />
                <br />
                Dr Y Revathi Reddy<br />
              </strong>
            </p>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            Dr. Revathi is a graduate of <b>Dental Surgery</b>. She did her <b>BDS from GOVERNMENT DENTAL COLLEGE VIJAYAWADA </b>& is a <b>‘GOLD MEDALIST’</b> of the college for being the Distinction Holder during her Bachelor’s.

            </p>
            
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            Interestingly & uniquely; She pursued her Master’s in CLINICAL EMBRYOLOGY AND PREIMPLANTATION GENETICS out of her innate interest in the field of Fertility Services. She received her <b>Master’s Degree in MSC Clinical Embryology & Preimplantation Genetics from The University of Mysore</b>.
            </p>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            Dr. Revathi is armed with Specialized skills Dedication and ensures great contribution to an extreme Success Rate and extreme Laboratory Standards. She is skilled in Laboratory management, All Embryology & Andrology work. Additionally, Dr. Revathi is an Expert in TROPHECTODERM BIOPSY for PGD.
                        </p>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            Fun fact: Being a Chatter Box since childhood, she can easily mingle with patients & make their worries disappear, and ensures their confidence. Thanks to her fine Skills through BDS which compliments fine micromanipulations in Embryology.

            </p>
            {/* <a href='/Contact' ><button style={{ alignItems: "right", backgroundColor: "#9932CC", color: 'white', marginBottom: '10px' }}>Read More</button></a> */}
          </div>
         
        </div>
      </div>

      {/* appointment..... */}

      <div className=" mb-5 " id="apoints" data-aos="zoom-in-down" data-aos-duration="2000"  style={{ backgroundImage: 'url("BACKD.jpg")', paddingTop: '28px', height: '170px' }}>
        <h5 className="container" style={{ textAlign: 'center', color: 'white' }}>Are you Considering to go for Treatment but worried about which one to choose?
          Why not Book an Appointment and our experts will be happy to advise you.</h5>
        <div className="mb-3 mt-2" style={{ textAlign: 'center' }} >
         <button className='btn ' onClick={handleShow} style={{ backgroundColor: '#b066d4', color: 'white' }} >Book appointment</button>
        </div>
      </div>

      {/* pop up book appointment...... */}

      <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{ color: '#b066d4' }}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >

              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
              <div className="help-block with-errors"></div>
            </Form.Group>
            <button type="submit" className="btn " name="contact" style={{ backgroundColor: "#b066d4", color: "whitesmoke" }} >Send Message</button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </Form>
        </Modal.Body>

      </Modal>

    

      {/* feature treatment */}

      <div className="container-fluid mt-3 mb-5 fusion-text feature " data-aos="fade-right" data-aos-duration="2000" style={{ display: 'block', fontSize: '15px', fontFamily: 'Roboto, sans-serif', marginBottom: '30px', paddingTop: '10px', paddingBottom: '10px',borderRadius:'15px' }}>

        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '20pxpx' }}>
          <span style={{ color: "#b066d4" }}>Feature Treatment</span>
          {/* <a>
          <span style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color:'#ff69b4', fontSize:' 26px'}}>
            Bhavya Fertility Centre
            </span>
          </a> */}
        </h1>
        <div style={{ display: 'flex', color: '#b066d4', marginLeft: '15px' }}>
          <div className="container mt-3">
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="card shadow-lg">
                  <img src='ivfImage.jpg' alt='' />
                  <div className="card-body">
                    <h5 className='card-title text-center'>In Vitro Fertilization(IVF)</h5>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3" >
                <div className="card shadow-lg-5" >
                  <img style={{ marginBottom: '20px' }} src='iuiImage5.jpg' alt='' />
                  <div className="card-body">
                    <h5 className='card-title text-center mt-2' >Intra Uterine Insemination(IUI)</h5>
                  </div>
                </div>
              </div>


              <div className="col-md-4 mb-3">
                <div className="card shadow-lg">
                  <img src='icsi3.jpg' alt='' />
                  <div className="card-body">
                    <h5 className='card-title text-center'>Intracytoplasmic Sperm Injection(ICSI)</h5>
                  </div>
                </div>
              </div>


              <div className="col-md-4 mb-3">
                <div className="card shadow-lg">
                  <img src='tesaImage.jpg' alt='' />
                  <div className="card-body">
                    <h5 className='card-title text-center'>Testicular Epididymal Sperm Aspiration(TESA)</h5>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="card shadow-lg" >
                  <img src='pesaImage1.jpg' alt='' />
                  <div className="card-body">
                    <h5 className='card-title text-center'>PERCUTANEOUS EPIDIDYMAL SPERM ASPIRATION (PESA)</h5>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="card shadow-lg" >
                  <img src='embryo_freezing.jpg' alt='' />
                  <div className="card-body">
                    <h5 className='card-title text-center'>EMBRYO FREEZING</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Achievments  counting...... */}

      {/* <div className="container mt-5 mb-5 p-5 shadow-lg p-2 mb-5 bg-body rounded" style={{ textAlign: 'center' }}>
        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '30px' }}>
          <span style={{ color: "#b066d4" }}>Achievments</span>
          {/* <a>
          <span style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color:'#ff69b4', fontSize:' 26px'}}>
            Bhavya Fertility Centre
            </span>
          </a> */}
        {/* </h1>
        <div className="row" >
          <div className="col-md-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter">
                <h1 className='value'>
                  {counterstart && <CountUp start={0} end={1000} duration={2} delay={0} />}
                </h1>
                <h5>+Happy Couples</h5>
              </div>
            </ScrollTrigger>
          </div>
          <div className="col-md-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter">
                <h1 className='value'>
                  {counterstart && <CountUp start={0} end={500} duration={2} delay={0} />}
                </h1>
                <h5>+Happy Couples</h5>
              </div>
            </ScrollTrigger>
          </div>

          <div className="col-md-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter">
                <h1 className='value'>
                  {counterstart && <CountUp start={0} end={700} duration={2} delay={0} />}
                </h1>
                <h5>+Happy Couples</h5>
              </div>
            </ScrollTrigger>
          </div>


          <div className="col-md-3">
            <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
              <div className="Counter">
                <h1 className='value'>
                  {counterstart && <CountUp start={0} end={200} duration={2} delay={0} />}
                </h1>
                <h5>+Happy Couples</h5>
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </div> */}


      


      {/* <div className="container mt-5 mb-5 " >
        <div>
          <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold',  }}>
            <span style={{ color: "#b066d4" }}>Patient Testimonials</span> */}
            {/* <a>
              <span style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color:'#ff69b4', fontSize:' 26px'}}>
                Bhavya Fertility Centre
                </span>
              </a> */}
          {/* </h1>

        </div>
        <Testmonials />
      </div>  */}








      {/* <div className="container mt-4 ">
        <div className="row">
          <div className="col-lg-5">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#9932CC" }}>Why Choose Us ?</span>
              {/* <a>
              <span style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color:'#ff69b4', fontSize:' 26px'}}>
                Bhavya Fertility Centre
                </span>
              </a> */}
      {/* </h1>
            <h3 style={{ color: "#d72599" }}>High Success Rate</h3>
            <p style={{ textAlign: 'justify' }}>When compared to other IVF centre, we have a huge successful rate. Depending on the condition of our patients, we offer the best IVF programs.</p>
            <h3 style={{ color: "#d72599" }}>High Success Rate</h3>
            <p style={{ textAlign: 'justify' }}>Through us, you will enjoy all the modern tools and techniques used to make IVF treatment popular. We are always welcoming new procedures to make conceiBJK</p>
            


          {/* Patient Testimonials.......... */}

      {/* <div className="col-lg-7 sm-12">
            <div>
              <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', }}>
                <span style={{ color: "#d72599" }}>Patient Testimonials</span>
                {/* <a>
              <span style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color:'#ff69b4', fontSize:' 26px'}}>
                Bhavya Fertility Centre
                </span>
              </a> */}
      {/* </h1> */}

      {/* </div>

          </div>
        </div>
      </div> */}


      {/* videos adding  */}

      <div className="container " style={{ textAlign: 'center', alignItems: 'center' }}>
        <h1 style={{ textAlign: 'center', color: '#b066d4' }}>Sucess Stories</h1>
        <div className="row mb-5">
          <div className="col-md-4 mb-5 my-5">
            <div className="w-85">
              <div className="ratio ratio-1x1" style={{ textAlign: 'center' }}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/HFonUyRQF3U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-5 my-5 ">
            <div className="w-85">
              <div className="ratio ratio-1x1">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/dL1mMry99ZY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-5 my-5">
            <div className="w-85">
              <div className="ratio ratio-1x1">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/KlqIQFKxv60" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Ratings...... */}

      <div className="container text-center mb-5 shadow-lg p-2 mb-5 bg-body rounded">
        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '30px' }}>
          <span style={{ color: "#b066d4" }}>Ratings</span>
          {/* <a>
          <span style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color:'#ff69b4', fontSize:' 26px'}}>
            Bhavya Fertility Centre
            </span>
          </a> */}
        </h1>
        <div className="row">
          <div className="col-md" >
            <img src="ivf.jpg" alt='' style={{ width: '200px' }}></img>
          </div>
          <div className="col-md">
            <img src="practo.jpg" alt='' style={{ width: '200px' }}></img>
          </div>
          <div className="col-md mt-2">
            <img src="justDail.jpg" alt='' style={{ width: '200px' }}></img>
          </div>
          <div className="col-md mt-2">
            <img src="faceBook.jpg" alt='' style={{ width: '200px' }}></img>
          </div>
          <div className="col-md">
            <img src="practo.jpg" alt='' style={{ width: '200px' }}></img>
          </div>

        </div>

      </div>

    </>
  )
}






export default Home