import React from 'react'
import './App.css'

const ICSI = () => {
  return (
    <>

      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>INTRACYTOPLASMIC SPERM INJECTION</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="/ICSI">ICSI</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#b066d4" }}>ICSI – Intracytoplasmic Sperm Injection</span>
            </h1>
            <p>
            Intracytoplasmic Sperm Injection or ICSI is a procedure in which a single sperm cell is directly injected into the cytoplasm of an egg. ICSI is performed by a skilled embryologist with a micromanipulator in the IVF laboratory. Bhavya fertility centre offers this advanced technique.
            </p>
            <p style={{ textAlign: 'justify' }}>
            For around half of the couples struggling to get pregnant, the problem lies with male fertility. ICSI is currently the best male infertility treatment. The difference mainly lies with the process of fertilization. In IVF, eggs and sperm are put together in a dish and a single sperm penetrates the egg and fertilization occurs. However, this requires a good number of sperms.            </p>

            <p style={{ textAlign: 'justify' }}>
            In ICSI, the process involves the injection of a single sperm that is picked up with a fine glass needle and then put into the cytoplasm of the egg cell. Very few sperm as compared to IVF is required and the ability of the sperm to penetrate the egg no longer holds importance.            </p>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5"  style={{ height: '300px',borderRadius:'10px' }} id="ServicesImg" src='icsi3.jpg' alt=''></img>
          </div>
        </div>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#dc48a8" }}>Who is recommended to have ICSI?</span>
        </h4>
        <p style={{ textAlign: 'justify' }}>
        ICSI is one of the important steps in an IVF cycle. ICSI significantly improves pregnancy rates when the cause is due to a male factor. Male infertility factors generally include low sperm count, poor motility, poor sperm quality, or when the sperm lacks the ability to penetrate the egg. Men with the absence of sperm in the semen due to some kind of blockage, which needs surgical retrieval can also be treated with the ICSI.        </p>

        <p style={{ textAlign: 'justify' }}>
          Apart from infertility in men, here other infertility problems for which ICSI is a suitable treatment:
        </p>

        <ul>
          <li>Less number of retrieved eggs</li>
          <li>Fertilization failure during a previous IVF cycle</li>
          <li>Repeated miscarriages during the previous cycle</li>
          <li>Age over 35 years</li>
          <li>Endometriosis, PCOS or any other problem</li>
          <li>Unfavourable embryo development in previous cycles</li>
          <li>Unexplained infertility</li>
        </ul>
      </div>
    </>
  )
}

export default ICSI