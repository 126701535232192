import React from 'react'

const Our_Facilities = () => {
  return (
    <>
      <div className="container-fluid strips" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>OUR FACILITIES</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
            <li ><a href="/">Services</a></li>
            <li ><a href="/Our_Facilities">Our Facilities</a></li>
          </ul>
        </div>
      </div>

      <div className="feature ourFacility"style={{ display: 'flex', color: '#b066d4' }}>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card shadow-lg">
                <img style={{ borderRadius: '5px' }} src='02.jpg' alt='' />

              </div>
            </div>

            <div className="col-md-4 mb-3 " >
              <div className="card shadow-lg-5" >
                <img style={{ marginBottom: '20px', borderRadius: '5px' }} src='019.png' alt='' />

              </div>
            </div>


            <div className="col-md-4 mb-3">
              <div className="card shadow-lg">
                <img style={{ borderRadius: '5px',height:'250px' }} src='07.jpg' alt='' />

              </div>
            </div>


            <div className="col-md-4 mb-3">
              <div className="card shadow-lg">
                <img style={{ borderRadius: '5px',  }} src='010.jpg' alt='' />

              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="card shadow-lg" >
                <img style={{ borderRadius: '5px' }} src='012.jpg' alt='' />

              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="card shadow-lg" >
                <img style={{ borderRadius: '5px',height:'250px'  }} src='013.png' alt='' />

              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="card shadow-lg" >
                <img style={{ borderRadius: '5px' }} src='016.png' alt='' />

              </div>
            </div>


            <div className="col-md-4 mb-3">
              <div className="card shadow-lg" >
                <img style={{ borderRadius: '5px' }} src='017.png' alt='' />

              </div>
            </div>


            <div className="col-md-4 mb-3">
              <div className="card shadow-lg" >
                <img style={{ borderRadius: '5px' }} src='018.png' alt='' />

              </div>
            </div>


           


          </div>
        </div>
      </div>


    </>
  )
}
export default Our_Facilities