import React from 'react'

 const World_class_facilities = () => {
  return (
    <>
    <div className="container-fluid strips mt-5 mb-5" id="strip" style={{ marginTop: '40px', display: 'flex' }}>
       <div className="col text-center strip1" style={{ color: 'white' }}>
         <div className="row mt-4" >
           <h1>WORLD CLASS FACILITIES</h1>
         </div>
         <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
           <li ><a href="/home">Home</a></li>
           <li ><a href="/">Services</a></li>
           <li ><a href="/World_class_facilities">World Class Facilities</a></li>
         </ul>
       </div>
     </div>
     <div className="container mt-5 mb-5 text-enter">
     <h1 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', margin: '15%',textAlign:'center' }}>
             <span style={{ color: "#b066d4" }}>Coming soon...</span>
           </h1 >
     </div>

   
   </>
  )
}
export default World_class_facilities