import React from 'react'

const Dr_Annapurna_Bhavya_Reddy = () => {
    return (
        <>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '40px', display: 'flex' }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>ABOUT US</h1>
                    </div>
                    <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>

            <div className="container mt-4 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#9932CC" }}>Dr Annapurna Bhavya Reddy</span>
                        </h1>

                        <p style={{ textAlign: 'justify' }}>
                            Dr Bhavya did her MBBS from Guntur Medical College and recipient of "Best outgoing student female" during her MBBS period.                        </p>

                        <p style={{ textAlign: 'justify' }}>
                            She did her M. S. in Obstetrics & Gynecology, from Government General Hospital, Guntur, and won Gold Medal for Scoring the highest marks in University Examination.                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Her Special interest in the field of infertility made her pursue FNB Reproductive Medicine, at the prestigious Madras Medical Mission Hospital Chennai for a period of 2 years. During her FNB, she has vast Exposure to different kinds of fertility issues. With an aim of providing the best, up-to-date fertility treatments to patients of all Socio-Economic Status, she has started Bhavya IVF & Fertility, a state of art IVF center in Guntur.                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            "Our main motto is to see happiness in the faces of couples having trouble in conceiving."                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5">
                        <img className=" img-thumbnail shadow-lg p-1 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '20px', width: '300px', marginTop: '50px' }} id="ServicesImg" src='bhavyamam1.jpg' alt=''></img>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Dr_Annapurna_Bhavya_Reddy